import React from "react"

import App from "../components/presave/App"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Live = () => {
  //   const [value, setValue] = useState("")
  //   const { speak } = useSpeechSynthesis()
  return (
    <Layout>
      <Seo title="Pre-Save" />

      <div className="max-w-xl mx-auto">
        <App />
        <nav
          aria-label="breadcrumb"
          className="w-full p-4 dark:bg-coolGray-800 dark:text-coolGray-100 flex flex-col items-center justify-center"
        >
          <ol className="flex h-8 space-x-2 dark:text-coolGray-100">
            <li className="flex items-center">
              <a
                rel="noopener noreferrer"
                href="/"
                title="Back to homepage"
                className="flex items-center hover:underline"
              >
                Home
              </a>
            </li>
            <li className="flex items-center space-x-1">
              <span className="dark:text-coolGray-400">/</span>
              <a
                rel="noopener noreferrer"
                href="/know"
                className="flex items-center px-1 capitalize hover:underline"
              >
                About
              </a>
            </li>
            <li className="flex items-center space-x-1">
              <span className="dark:text-coolGray-400">/</span>
              <a
                rel="noopener noreferrer"
                href="/started"
                className="flex items-center px-1 capitalize hover:underline font-bold "
              >
                Features
              </a>
            </li>
            <li className="flex items-center space-x-1">
              <span className="dark:text-coolGray-400">/</span>
              <a
                rel="noopener noreferrer"
                href="/contact"
                className="flex items-center px-1 capitalize hover:underline"
              >
                Contact
              </a>
            </li>
          </ol>
          <footer>
            © {new Date().getFullYear()}, An Idea by
            {` `}
            <a className="text-center text-sm" href="https://www.chakama.co.zw">
              George Chakama
            </a>
          </footer>
        </nav>
      </div>
    </Layout>
  )
}

export default Live
