import React, { useState } from "react"
import TodoForm from "./TodoForm"
import Todo from "./Todo"
import Modal from "react-modal"
// Todo
// issolate component in react and change everything
// Fix Modal
function TodoList() {
  const customStyles = {
    content: {
      top: "20%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: "500",
    },
  }

  const [todos, setTodos] = useState([])

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const addTodo = todo => {
    const newTodos = [todo, ...todos]

    if (!todo.text || /^\s*$/.test(todo.text)) {
      return
    }
    if (newTodos.length !== 0) {
      var i = 1
      while (i < newTodos.length) {
        if (newTodos[i].text === todo.text) {
          setModalIsOpen(true)
        }
        i++
      }
    }
    setTodos(newTodos)
  }

  const updateTodo = (todoId, newValue) => {
    if (!newValue.text || /^\s*$/.test(newValue.text)) {
      return
    }

    setTodos(prev => prev.map(item => (item.id === todoId ? newValue : item)))
  }

  const removeTodo = id => {
    const removeArr = [...todos].filter(todo => todo.id !== id)

    setTodos(removeArr)
  }

  return (
    <div>
      {/* <h1 >Pre-save your Phrases</h1> */}

      <h1 className="text-5xl leading-none text-center mt-8 text-[#33c3b0]">
        Save Speech
      </h1>
      <p className="text-xl text-center my-4 text-gray-400">
        pre-save your responses for conversation
      </p>
      

      <TodoForm onSubmit={addTodo} />
      <div class="z-40">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          style={customStyles}
        >
          <div
            class="relative py-3 pl-4 pr-10 leading-normal text-red-700 bg-red-100 rounded-lg z-40"
            role="alert"
          >
            <p>You have added a Phrase that is already on the List 😮😰.</p>
            <span class="absolute inset-y-0 right-0 flex items-center mr-4">
              <button onClick={() => setModalIsOpen(false)}>
                <svg
                  class="w-4 h-4 fill-current"
                  role="button"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </span>
          </div>
        </Modal>
      </div>
      <Todo todos={todos} removeTodo={removeTodo} updateTodo={updateTodo} />
    </div>
  )
}

export default TodoList
