import React from "react"
import "./App.css"
import SavedList from "./components/TodoList"
// Todo
// issolate component in react and change everything
function App() {
  return (
    <div className="saves-app">
      <SavedList />
    </div>
  )
}

export default App
