import React, { useState } from "react"
import TodoForm from "./TodoForm"

import { useSpeechSynthesis } from "react-speech-kit"
// Todo
// issolate component in react and change everything
// Delete React-icons package

function Todo({ todos, removeTodo, updateTodo }) {
  const [edit, editTodo] = useState({
    id: null,
    value: "",
  })
  const { speak } = useSpeechSynthesis()

  const submitUpdate = value => {
    updateTodo(edit.id, value)
    editTodo({
      id: null,
      value: "",
    })
  }

  if (edit.id) {
    return <TodoForm edit={edit} onSubmit={submitUpdate} />
  }

  return todos.map((todo, index) => (
    <div
      className={todo.isComplete ? "todo-row complete" : "todo-row"}
      key={index}
    >
      <div className="flex text-left mb-4 shadow-inner border-2 rounded-md border-teal-200">
        <div className="grow  overflow-visible  p-2 my-2 text- w-3/6 flex justify-start  content-center">
          <p className="text-gray-500">- {todo.text} </p>
        </div>

        <div class="flex items-center -space-x-4 hover:space-x-1 ">
          <button
            class="hover:animate-spin z-10 block p-4 text-green-700 transition-all bg-green-100 border-2 border-white rounded-full active:bg-green-50 hover:scale-110 focus:outline-none focus:ring"
            type="button"
            onClick={() => speak({ text: todo.text })}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
              />
            </svg>
          </button>

          <button
            class="hover:animate-spin z-20 block p-4 text-blue-700 transition-all bg-blue-100 border-2 border-white rounded-full active:bg-blue-50 hover:scale-110 focus:outline-none focus:ring"
            type="button"
            onClick={() => editTodo({ id: todo.id, value: todo.text })}
          >
            <svg
              class="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </button>

          <button
            class=" hover:animate-spin z-30 block p-4 text-red-700 transition-all bg-red-100 border-2 border-white rounded-full hover:scale-110 focus:outline-none focus:ring active:bg-red-50"
            type="button"
            onClick={() => removeTodo(todo.id)}
          >
            <svg
              class="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  ))
}

export default Todo
