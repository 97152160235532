import React, { useState, useEffect, useRef } from "react"
// Todo
// issolate component in react and change everything
function TodoForm(props) {
  const [input, setInput] = useState(props.edit ? props.edit.value : "")
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  })

  const handleChange = e => {
    setInput(e.target.value)
  }
  const handleSubmit = e => {
    e.preventDefault()

    props.onSubmit({
      id: Math.floor(Math.random() * 10000),
      text: input,
    })
    setInput("")
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="my-8">
        {props.edit ? (
          <>
            <div class="mt-8 sm:flex">
              <div class="sm:flex-1">
                <input
                  type="text"
                  value={input}
                  onChange={handleChange}
                  ref={inputRef}
                  placeholder=" Add Phrase"
                  class="w-full p-3 text-gray border-2 border-blue-400 rounded-lg focus:outline-none focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
                />
              </div>

              <button
                onSubmit={handleSubmit}
                class="flex items-center justify-between w-full px-5 py-3 mt-4 font-medium text-blue-400 border-2 border-blue-400 rounded-lg sm:w-auto sm:mt-0 sm:ml-4 hover:bg-teal-400 hover:text-white hover:border-teal-400"
              >
                Update
                <svg
                  class="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </button>
            </div>
          </>
        ) : (
          <>
            <div class="mt-8 sm:flex">
              <div class="sm:flex-1">
                <input
                  type="text"
                  value={input}
                  onChange={handleChange}
                  ref={inputRef}
                  placeholder=" Add Phrase"
                  class="w-full p-3 text-gray border-2 border-gray-400 rounded-lg focus:outline-none focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
                />
              </div>

              <button
                onSubmit={handleSubmit}
                class="flex items-center justify-between w-full px-5 py-3 mt-4 font-medium text-white bg-[#33c3b0] rounded-lg sm:w-auto sm:mt-0 sm:ml-4 hover:bg-teal-400"
              >
                Add
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  class="flex-shrink-0 w-4 h-4 ml-3"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  )
}

export default TodoForm
